<template>
    <v-card elevation="24" shaped>
        <v-card-title>{{ title }} </v-card-title>
        <v-divider width="50%" class="blue accent-2"></v-divider>
        <v-card-text class="dense">
            <v-container>
                <v-select v-if="!data.event && isGroup && esAsistente"
                      label="Medico"
                      :items="medicoGrupo"
                      item-text="name"
                      item-value="id"
                      v-model="idMedicoSelected"
                      outlined
                      dense
                      class="mb-n6 mx-auto"
                    >
                    </v-select>
               <v-row dense v-if="!isAppointment">
                    <v-col cols="12">
                        <v-form ref="evtform" v-model="evtform">
                            <v-text-field
                                v-model="name"
                                :counter="150"
                                :rules="nameRules"
                                label="Nombre del evento"
                                required
                                ref="name"
                                clearable
                                persistent-hint
                                :hint="nameHint"
                                :disabled="allDay"
                            ></v-text-field>
                            <v-text-field
                                v-model="ubicacion"
                                :counter="200"
                                :rules="ubicacionRules"
                                label="Agregar ubicación"
                                required
                                ref="ubicacion"
                                clearable
                                :disabled="allDay"
                            ></v-text-field>
                        </v-form>
                    </v-col>
                    <v-col cols="12" class="text-left">
                        <v-switch
                            v-model="allDay"
                            label="Todo el día"
                            :disabled="disabled"
                            dense
                        ></v-switch>
                    </v-col>

                </v-row>
                <v-row dense v-if="isAppointment">
                    <v-col cols="12" v-if="data.event">
                        <span class="title">
                            {{data.event.name}}
                        </span>
                    </v-col>
                    <v-col cols="12" class="d-flex" v-else>
 
                        <remote-search
                            ref="busq"
                            url="/api/pacientes/buscar/"
                            label="Búsqueda de paciente"
                            min="4"
                            placeholder="Escriba el nombre del paciente"
                            @change="onChange"
                            @message="message"
                            :disabled="disabled"
                            dense
                        ></remote-search>
                        <v-btn icon title="Nuevo paciente" color="success" class="ml-2 mt-3" fab dark small @click="$emit('add-patient')">
                            <v-icon>mdi-account-plus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row dense v-if="times.length>0">
                    <v-col>
                        <v-text-field
                            :value="datetime.date"
                            label="Fecha"
                            outlined
                            readonly
                            :disabled="disabled"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col v-show="!data.event||!(data.event&&data.event.tipo==1&&data.event.end.substring(0,10)!=data.event.start.substring(0,10))">
                        <v-select
                            :items="times"
                            label="Hora"
                            outlined
                            v-model="time"
                            :disabled="disabled||allDay"
                            dense
                        ></v-select>
                    </v-col>
                    <v-col v-show="!data.event||!(data.event&&data.event.tipo==1&&data.event.end.substring(0,10)!=data.event.start.substring(0,10))">
                        <v-select
                            :items="duraciones"
                            label="Duración (min)"
                            outlined
                            v-model="duracion"
                            :disabled="disabled||allDay"
                            dense
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row dense v-if="isAppointment">
                    <v-col>
                        <v-select
                            :items="motivos"
                            label="Motivo"
                            outlined
                            v-model="motivo"
                            :disabled="disabled"
                            dense
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row dense v-if="isAppointment">
                    <v-col cols="12">
                            <v-text-field label="Nota para cita" v-model="note" outlined dense @change="updateNote"
                                         max-length="500" 
                                         counter="500"
                                         @click:append-outer="updateNote"
                                         @keydown.enter="updateNote"
                                         append-outer-icon="mdi-check"
                                         title="Breve anotación de la cita (campo opcional, no es parte de la consulta)"
                                         ></v-text-field>
                    </v-col>
                </v-row>

            </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pb-4 mr-4">
            <v-spacer></v-spacer>
            <v-btn
                @click="$emit('cancel')"
                :disabled="disabled || working"
                small
                >Cancelar</v-btn
            >
            <v-btn
                color="warning"
                @click="confirmDelete = true"
                :disabled="working || disabled"
                v-if="data.event"
                outlined
                small
                >Eliminar</v-btn
            >
            <v-btn
                v-if="event && event.id && event.tipo==0"
                small
                outlined
                class="mx-2"
                color="info"
                @click="goTo"
                >{{
                    usuario.roles.includes("ASI")
                        ? "Ver pagos"
                        : "Ir a consulta"
                }}
            </v-btn>

            <v-btn
                color="primary"
                @click="save"
                :disabled="disable"
                :loading="working"
                small
                v-else-if="!event.id"
                >Guardar</v-btn
            >


            <v-btn color="info" icon
                      class="d-flex flex-row-reverse"
                      @click="customMenu($event, tarea)"
            v-if="tarea != null && tarea.infoCita.status == 1 && (!tarea.infoCita.pagoEfectuado ||  tarea.infoCita.facturada != 1)">
                      <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>

        </v-card-actions>
        <confirm
            v-if="confirmDelete"
            @cancel="confirmDelete = false"
            @confirm="
                confirmDelete = false;
                deleteEvent();
            "
        ></confirm>

        <opcion-cfdi v-if="tarea && facturar" :uuid="tarea.infoCita.uuid" :typename="typename" @cancel="facturar= false"></opcion-cfdi>

        <context-menu
                      v-if="showContextMenu"
                      :nativeEvent="clickEvt"
                      :optionsArr="opts"
                       @destroy="clearClickEvento"
                       @selected="optionSelected"
                ></context-menu>
    </v-card>


</template>
<script>
import RemoteSearch from "@/components/RemoteSearch";
import { Urls } from "@/consts.js";
import OpcionCFDI from "@/components/OpcionCFDI.vue";
function leftPad(n) {
    return Math.abs(n) < 10 ? "0" + n : n;
}

export default {
    name: "Cita",
    components: {
        "remote-search": RemoteSearch,
        Confirm: () => import("@/components/Confirm"),
        ContextMenu: () => import("@/components/ContextMenu"),
        "opcion-cfdi": OpcionCFDI,
    },
    props: {
        data: {
            default: () => ({
                datetime: { date: new Date().toISOString().substr(0, 10) },
                event: null,
                place: null,
                z: null,
                t:0
            }),
            type: Object,
        },
        disabled: { default: false },

    },
    data: function () {
        return {
            idMedicoSelected:null,
            medicoGrupo: [],
            datetime: {},
            event: {},
            place: "",
            form: {},
            confirmDelete: false,
            working: false,
            date: new Date().toISOString().substr(0, 10),
            time: "00:00",
            times: [],
            motivo: 0,
            duracion: 30,
            pac: null,
            duraciones: [15, 30, 45, 60, 90, 120],
            motivos: [
                { text: "Seleccione el motivo de la consulta", value: 0 },
                { text: "Control de tratamiento", value: 1 },
                { text: "Consulta", value: 2 },
                { text: "Limpieza", value: 3 },
                { text: "Urgencia", value: 4 },
            ],
            clickEvt:null,
            opts:[],
            tarea:null,
            facturar:false,
            typename:"appt",
            allDay:false,
            evtform: false,
            name: '',
            ubicacion:'',
            nameRules: [
                v => !!v || 'Este campo es obligatorio',
                v => (v && v.trim().length > 4 && v.trim().length<=150) || 'Sólo se aceptan caracteres; mínimo 5, máximo 150',
            ],
            ubicacionRules:[
            (v) =>
                  !v ||
                  (!!v && v.trim().length> 4 && v.trim().length<=200) ||
                  'Sólo se aceptan caracteres; mínimo 5, máximo 200',
            ],
            note:null,
        };
    },

    computed: {
        esAsistente: function () {
            return this.$store.state.usuario.roles.includes("ASI");
        },
        isGroup: function(){
            return this.$store.state.group;
        },
        title: function () {
            if(this.isAppointment)
                return this.data.event?'Datos de la cita':'Programación de cita';
            else
                return this.data.event?'Datos del evento':'Programación de evento';

        },
        usuario: function () {
            return this.$store.state.usuario;
        },
        showContextMenu:function(){
            return this.clickEvt && this.tarea;
        },
        isAppointment:function(){
            if(this.data.event)
                return this.data.event.tipo==0;
            else{
                return (this.data.tipo ? parseInt(this.data.tipo) : 0)==0;
            }

        },
        nameHint:function(){
            return this.data.event? "":(this.fullday?"Evento de día completo":("El evento durará "+this.duracion+" minutos"));
        },
        disable: function() {
            return (this.isAppointment&&!this.pac)||(!this.isAppointment&&!this.evtform)||this.disabled;
        }
    },

    methods: {
        onChange: function (value) {
            this.pac = value;
        },
        save: function () {

            let client = leftPad(new Date().getMinutes()) + ":" + leftPad(new Date().getSeconds());
            const aDay = !this.isAppointment && this.allDay === true;
            const h1=aDay==true?'00:00':(this.time||client)+':00';
            const d=aDay==true?1439:this.duracion;
            const p=this.pac?this.pac.id:0;

            var params = {
                c: this.data.place,
                p: p,
                f1: this.datetime.date + " " + h1,
                d: d,
                m: this.motivo,
                z: this.data.z,
                n: this.name,
                t:this.isAppointment?0:1,
                u:this.ubicacion,
                mid:this.idMedicoSelected,
                nt:this.note
            };
            this.working = true;
            this.$http
                .post(Urls.AGENDA_URL + "/registrar", params)
                .then((data) => {
                    if (data) {
                        this.$emit("appointment-added", data);
                        if (this.$refs.busq)
                            this.$refs.busq.reset();
                    } else
                        this.message("No se pudo guardar la cita", "warning");
                    this.working = false;
                }).then(function(){
                    this.working= false;
                }.bind(this));

        },
        deleteEvent: function () {
            this.$http
                .delete(Urls.AGENDA_URL + "/borrar/"+this.event.tipo+"/" + this.event.id)
                .then(
                    function (data) {
                        if (data) {
                            this.$emit("appointment-deleted", this.event.id);
                        }
                    }.bind(this)
                );
        },
        setPacienteEmpty() {
            this.pacs = [];
            this.pac = null;
        },
        goTo: function () {
            if (this.usuario.roles.includes("ASI")) {
                this.$router.push({
                    name: "pagos",
                    params: {
                        pacienteId: this.event.p,
                    },
                });
            } else {
                this.$router.push({
                    name: this.event.tipo == 2 ? "planes" : "antecedentes",
                    params: {
                        pacienteId: this.event.p,
                        tareaId: this.event.id,
                    },
                });
            }
        },
        init: function (obj) {
            if (obj.event) {
                // Se trata de una edición
                var dt = obj.event.start.split(" ");
                var date = dt[0].split("-");
                var time = dt[1].split(":");
                var cdt = {};
                cdt.hour = time[0];
                cdt.minute = time[1];
                cdt.year = date[0];
                cdt.month = date[1];
                cdt.day = date[2];
                cdt.date = dt[0];
                cdt.time = dt[1].substr(0, 5);

                this.datetime = cdt;
                this.date = cdt.date;
                this.time = cdt.time;
                this.event = obj.event;
                this.motivo = obj.event.motivo ?? 0;

                this.duracion =
                    (Date.parse(obj.event.end) - Date.parse(obj.event.start)) /
                    1000 /
                    60;

                if(obj.event.tipo>0){//Verificar si es evento de todo el día
                    this.allDay = this.duracion>=1439;
                    this.name= obj.event.name;
                    this.ubicacion = obj.event.location;
                }

            } else {
                this.datetime = obj.datetime;
            }
            this.place = obj.place;
            var start = this.datetime.minute < 30 ? 0 : 30;
            var end = start + 25;
            var times = [];
            var min = 1440;
            var tmp;
            var current;
            var hour;
            for (; start < end; start += 5) {
                hour =
                    this.datetime.hour +
                    ":" +
                    (start < 10 ? "0" + start : start);
                times.push(hour); // Por la reactividad no lo haré uno por uno
                tmp = Math.abs(this.datetime.minute - start);
                if (tmp < min) {
                    // Buscar el horario más cercano al seleccionado
                    min = tmp;
                    current = hour;
                }
            }
            // Primero se debe "settear" times y luego time
            this.times = times;
            if (!obj.event) {
                this.$nextTick(function () {
                    this.time = current;
                });

                if(this.data.tipo&&this.data.tipo==2) {
                    this.$nextTick(function(){
                        this.ubicacion = '';
                        this.name="No dar consultas";
                        this.allDay=true;
                        this.time=current;
                        this.working=true;
                        this.save();
                    });
                }
            }
        },
        message: function (msg, color) {
            this.$emit("message", msg, color);
        },
        error: function (err) {
            if (
                typeof err.request !== "undefined" &&
                err.request.responseText &&
                err.response.status === 412
            ) {
                let e = JSON.parse(err.request.responseText);
                err = e.message || err;
            }
            this.message(err, "error");
        },
        setNuevoPaciente(obj){
            this.$refs.busq.setItem(obj);
        },
        customMenu(event, tarea){
            this.clearClickEvento();
            this.$nextTick(() => {
                this.clickEvt = event;
                this.setOpts(tarea);
                event.stopPropagation();
            });
        },
        clearClickEvento:function(){
            if (this.clickEvt) this.clickEvt = null;
        },
        setOpts(tarea){
           var options = [];

           options.push({text:'Cobrar', key:'cobrar', disabled:tarea.infoCita.pagoEfectuado});
           if(this.usuario.isoPais == 'MX'){
               var text = tarea.infoCita.pagoEfectuado ? 'Facturar':'Cobrar y facturar';
               var disabled = tarea.infoCita.pagoEfectuado && tarea.infoCita.facturada == 1;
               var key = !tarea.infoCita.pagoEfectuado ? 'cobrar-facturar': 'facturar';
               options.push({text:text, key:key, disabled:disabled});
           }
           this.opts = options;
        },
        getTarea: function () {
            this.$http
                .get(Urls.AGENDA_URL + "/tarea/" + this.event.id)
                .then((data) => {
                    if (!data) return;
                    this.tarea = data;
                    this.note = this.tarea.note;
                });
        },
        optionSelected(key){
            switch (key) {
                    case "cobrar":
                      this.cobrarFacturar(true, false);
                      break;
                    case "cobrar-facturar":
                        this.cobrarFacturar(true, true);
                        break;
                    case "facturar":
                        this.cobrarFacturar(false, true);
                        break;
                    default:
                      break;
                  }
        },
        cobrarFacturar(cobrar, facturar){
            if(cobrar && this.tarea.infoCita.pagoEfectuado ){
                cobrar = false;
            }

            if(facturar && this.tarea.infoCita.facturada == 1){
                facturar = false;
            }

            if(cobrar){

            this.$http
                .post(Urls.AGENDA_URL + "/cobrarConsulta", {
                    tareaId: this.tarea.id,
                    facturar: facturar?1:0
                })
                .then((data) => {
                    if (!data) return;
                    this.dialogCosto = false;
                    if(data == -1){
                      this.$root.showMessage("La cita que desea cobrar, no fue encontrada", 'warning');

                    }
                    else if(data == -2){
                        this.$root.showMessage("La cita ya fue cobrada anteriormente", 'warning');
                    }
                    else if(data == -3){
                        this.$root.showMessage("La cita ya fue facturada", 'warning');
                    }
                    else{
                        this.$root.showMessage(
                            "Se cobró la consulta correctamente", 'info'
                        );
                        this.tarea.infoCita.pagoEfectuado = true;
                    }
                    this.facturar = facturar;
                });
            }
            else{
                this.facturar = true;
            }

        },
        getMedicosGrupo: function () {
            this.$http
                .get(Urls.ASISTENTES_URL + "/listmedicogrupo/" + this.datetime.date)
                .then((data) => {
                    if (!data) return;
                    this.medicoGrupo = data;
                    this.idMedicoSelected = this.medicoGrupo[0].id;

                });
        },
        updateNote: function() {
            if (this.event && this.event.id) {
            var id=this.event.id;
            var n = this.note;
            this.$http
                .put( Urls.AGENDA_URL +`/appointment-note/${id}?n=${n}`)
                .then((data)=>{
                    if (data>0) {
                        this.message('Nota guardada','info');
                    } else this.message('No se pudo guardar la nota','warning')
                })
                .catch((e)=>{
                    this.error(e);                    
                }).then(()=>this.working=false);                
            }
        }
    },

    watch: {
        data(obj) {
            this.init(obj);
        },
    },
    mounted() {
        this.init(this.data);
        if(this.data.event && this.data.event.id && this.data.event.tipo == 0){
            this.getTarea();
        }

        if (!this.data.event && this.isGroup && this.esAsistente) {
            this.getMedicosGrupo();
        } 
    },
};
</script>
